.errorPage {
  --error-alert-margin-bottom: 2.5rem;
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (--mq-from-desktop) {
    --error-alert-margin-bottom: 3rem;
  }

  &__title {
    font-family: var(--font_hind);
    font-size: 2.4375rem;
    font-weight: 600;
    color: var(--color_error-text);
    text-align: center;

    @media (--mq-from-tablet) {
      font-size: 2.375rem;
    }

    @mixin tvDevice {
      font-size: 2.9375rem;
    }
  }

  &__text {
    max-width: 50rem;

    @mixin tvDevice {
      font-size: 1.9375rem;
    }
  }

  &__button {
    max-width: 20rem;
    margin-bottom: 1.25rem;

    @mixin tvDevice {
      max-width: 30rem;
    }
  }

  &__moreInfos {
    max-width: 30rem;
    text-align: center;

    @mixin tvDevice {
      font-size: 1.9375rem;
    }
  }

  &__alert {
    margin-bottom: var(--error-alert-margin-bottom);
  }
}
